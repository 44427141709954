.popUpContainer{
    border-radius:20px;
    border-style:outset;
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color:lavender;
    padding: 0.7rem;
}

.popUpTopBar{
    height: 40px;
    padding: 2px;
    text-align: center;
}

.popUpTitle{
    color:blue;
    font-weight: bold;
    background-color:aliceblue;
    border-radius: 6px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}

.popUpIsLarge{
    height: 90%;
    width: 90%;
}

.popUpIsMedium{
    height: 60%;
    width: 80%;
}

.popUpIsSmall{
    height: 30%;
    width: 70%;
}

@keyframes popUpFadeIn 
{
    from { 
        visibility: hidden;
        opacity: 0;
    }
    to { 
        visibility: visible;
        opacity: 1;
    }
}

@keyframes popUpFadeOut 
{
    from { 
        visibility: visible;
        opacity: 1;
    }
    to { 
        visibility: hidden;
        opacity: 0;
    }
}

.popUpFadeIn{
    animation: popUpFadeIn 0.5s ease-in-out;
    visibility: visible;
}

.popUpFadeOut{
    animation: popUpFadeOut 0.5s ease-in-out;
    visibility: hidden;
}
.popUpFirstLoad{
    visibility: hidden;
}

.closeBtn{
    position:relative;
	float:right;
	z-index: 4; 
}

@media (min-width: 900px) {

    .popUpIsMedium{
        width: 60%;
    }

    .popUpIsSmall{
        width: 30%;
    }
    
    .popUpTitle{
        font-size: 1.4rem;
    }
}