body 
{
	margin: auto;
    padding: 0.5rem;
   
    /* font-size: 1.2em; */
}

header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background-color:darkgrey;
    border-radius: 10px;
}

h3{
    color: blue;
}

.middle{
    display: block;
    background-color:aliceblue;
    border-radius: 10px;
}


.heading{
    display: block;
    text-align: center;
    font-size: 2rem;
    color:blue;
    min-width: 100%;
    margin-bottom: 0.5rem;
}

.subHeading{
    display: block;
    text-align: center;
    margin-top: 0.5rem;
}

.mainNav{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: 0.5rem;
}

.red{
    border-radius: 6px;
    border-style: ridge;
    background-color: red;
    color:azure;
    padding: 5px;
    
}

.timers{
    min-width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media (min-width: 900px) {
	
    .main-container{
		width: 80%;
        max-width: 1280px;
        margin: auto;
	}

    .heading{
        font-size: 4rem;
    }
	
}

@media (min-width: 500px) {

    body{
        background-image: url("./bkg2.jpg");
    }

    .middle{
        padding: 0.5rem;
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
    }
    .heading{
        font-size: 3rem;
    }
}