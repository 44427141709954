.reportBox{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color:cornflowerblue;
    border-radius: 10px;
}

.reportBlock{
    background-color:aqua;
    padding: 0.25rem;
    margin: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius:6px;
}

.reportSubtitle{
    text-align: center;
}